<template>
    <TsPanelCustomScroll :loading="loading">
        <ts-page-title
            :title="$t('employeeProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('employeeProfile.pageTitle'),
                    href: '/admin/hr/employee-profiles'
                },
                {
                    text: $t('edit'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <div class="col-md-4 tw-space-y-3">
                <PhotoName v-model="model" :validate="errors" />
                <HiredDate v-model="model" :validate="errors" />
            </div>
            <div class="col-md-8">
                <ts-panel>
                    <ts-panel-wrapper class="tw-space-y-2">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label class="required">{{
                                            $t("employeeProfile.phone")
                                        }}</label>
                                        <input
                                            v-model="model.phone"
                                            type="text"
                                            class="form-control"
                                            :placeholder="
                                                $t('employeeProfile.phone')
                                            "
                                        />
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('phone')"
                                        >
                                            {{ errors.first("phone") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label>{{
                                            $t("employeeProfile.email")
                                        }}</label>
                                        <input
                                            v-model="model.email"
                                            type="email"
                                            class="form-control"
                                            :placeholder="
                                                $t('employeeProfile.email')
                                            "
                                        />
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('email')"
                                        >
                                            {{ errors.first("email") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label class="required">{{
                                            $t("employeeProfile.nationality")
                                        }}</label>
                                        <input
                                            v-model.trim="model.nationality"
                                            type="text"
                                            class="form-control"
                                            :placeholder="
                                                $t(
                                                    'employeeProfile.nationality'
                                                )
                                            "
                                        />
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('nationality')"
                                        >
                                            {{ errors.first("nationality") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label class="required">{{
                                            $t("employeeProfile.ssnNo")
                                        }}</label>
                                        <input
                                            v-model="model.ssn_no"
                                            type="text"
                                            class="form-control"
                                            :placeholder="
                                                $t('employeeProfile.ssnNo')
                                            "
                                        />
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('ssn_no')"
                                        >
                                            {{ errors.first("ssn_no") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label class="required">{{
                                            $t("employeeProfile.issuedDate")
                                        }}</label>
                                        <a-date-picker
                                            v-model="model.ssn_issued_date"
																						format="DD-MM-YYYY"
																						valueFormat="DD-MM-YYYY"																						
                                            class="tw-w-full"
                                            :placeholder="
                                                $t('employeeProfile.issuedDate')
                                            "
                                        ></a-date-picker>
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('ssn_issued_date')"
                                        >
                                            {{
                                                errors.first("ssn_issued_date")
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 tw-space-y-1">
                                        <label class="required">{{
                                            $t("employeeProfile.expiredDate")
                                        }}</label>
                                        <a-date-picker
                                            v-model="model.ssn_expiry_date"
																						format="DD-MM-YYYY"
																						valueFormat="DD-MM-YYYY"																						
                                            class="tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'employeeProfile.expiredDate'
                                                )
                                            "
                                        ></a-date-picker>
                                        <div
                                            class="tw-text-red-600 tw-text-xs"
                                            v-if="errors.has('ssn_expiry_date')"
                                        >
                                            {{
                                                errors.first("ssn_expiry_date")
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 tw-space-y-1">
                                <label class="required">{{
                                    $t("employeeProfile.issuedAt")
                                }}</label>
                                <textarea
                                    v-model.trim="model.ssn_issued_at"
                                    rows="7"
                                    class="form-control"
                                    :placeholder="
                                        $t('employeeProfile.issuedAt')
                                    "
                                ></textarea>
                                <div
                                    class="tw-text-red-600 tw-text-xs"
                                    v-if="errors.has('ssn_issued_at')"
                                >
                                    {{ errors.first("ssn_issued_at") }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 tw-space-y-1">
                                <label>{{
                                    $t("employeeProfile.address")
                                }}</label>
                                <textarea
                                    v-model.trim="model.address"
                                    rows="7"
                                    class="form-control"
                                    :placeholder="$t('employeeProfile.address')"
                                ></textarea>
                                <div
                                    class="tw-text-red-600 tw-text-xs"
                                    v-if="errors.has('address')"
                                >
                                    {{ errors.first("address") }}
                                </div>
                            </div>
                        </div>
                    </ts-panel-wrapper>
                </ts-panel>
            </div>
        </div>
        <template v-slot:footer>
            <ts-button
                @click.prevent="$router.push({ name: 'employee-profile' })"
                :disabled="loading"
                >{{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onUpdate"
                :disabled="loading"
                >{{ $t("save") }}</ts-button
            >
        </template>
    </TsPanelCustomScroll>
</template>

<script>
import { Errors } from "form-backend-validation";
import PhotoName from "./components/photo-name.vue";
import HiredDate from "./components/hire-date.vue";
import { mapActions, mapState } from 'vuex';
export default {
    name: "employeeProfileEdit",
    components: {
        PhotoName,
        HiredDate
    },
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
								employee_id: '',
                photo: "",
                employee_name_kh: "",
                employee_name_en: "",
                sex: "",
                phone: "",
                email: "",
                address: "",
                date_of_birth: "",
                employment_date: "",
                probation_end_date: "",
                status_id: 1,
                status_effective_date: "",
                nationality: "",
                ssn_no: "",
                ssn_issued_date: "",
                ssn_expiry_date: "",
                ssn_issued_at: ""
            }
        };
    },
		computed: {
			...mapState('hr/employeeProfile', ['edit_data'])
		},
    methods: {
			...mapActions('hr/employeeProfile', ['getFormViewData', 'find', 'upload']),
				async fetchFormData(){
					this.loading = true
					await this.getFormViewData({ params: {}, index: undefined })
					this.loading = false
				},
        onUpdate() {
						this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("hr/employeeProfile/update",{
									id: this.model.employee_id,
									data: this.model
								})
                .then(async response => {
                    await this.uploadLogo(response.employee_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "employee-profile" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async uploadLogo(empId) {
            if (this.model.photo instanceof File || this.model.photo instanceof Blob) {
                let formData = new FormData();
                formData.append("file", this.model.photo);
                formData.append("employee_id", empId);
                let response = await this.upload(formData);
                return response;
            }
            return "";
        },
        async setEditData() {
						await this.find(this.$route.params.id)
						await this.fetchFormData()
            this.model.employee_id = this.edit_data.employee_id
            this.model.photo = this.edit_data.photo
            this.model.employee_name_kh = this.edit_data.employee_name_kh
            this.model.employee_name_en = this.edit_data.employee_name_en
            this.model.sex = this.edit_data.sex
            this.model.phone = this.edit_data.phone
            this.model.email = this.edit_data.email
            this.model.address = this.edit_data.address
            this.model.date_of_birth = this.edit_data.date_of_birth
            this.model.employment_date = this.edit_data.employment_date
            this.model.probation_end_date = this.edit_data.probation_end_date
            this.model.status_id = this.edit_data.status_id
            this.model.status_effective_date = this.edit_data.status_effective_date
            this.model.nationality = this.edit_data.nationality
            this.model.ssn_no = this.edit_data.ssn_no
            this.model.ssn_issued_date = this.edit_data.ssn_issued_date
            this.model.ssn_expiry_date = this.edit_data.ssn_expiry_date
            this.model.ssn_issued_at = this.edit_data.ssn_issued_at
        }
    },
		beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setEditData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("hr/employeeProfile/RESET_STATE");
        next();
    }
};
</script>
